import { defineStore } from 'pinia';
import apiUserService from '@/services/api/userService';
import client from '@/services/api/apiConnector';
import router from '@/router/index';
import { User } from '@/models/interfaces/User.interface';
import { useRoadbookStore } from './roadbook';
import { deleteRoadBook } from '@/utils/functions/utils';
import { getFaro } from '@raccourci/raccourci-faro-vue';
export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: {} as User,
    entityId: 0 as number,
    users: [] as Array<User>,
    noSessions: false as boolean,
    noSessionsRoute: '' as string,
    maxSessions: 0 as number,
    activeSessions: [] as Array<User>,
    licencesAccess: false as boolean,
    licencesProAccess: true as boolean,
    initialUser: {} as User,
    firstConnexion: true as boolean,
    loggingOut: false as boolean,
    hasStatisticsAccess: false as boolean,
    webhooksAccess: false as boolean,
    statisticsEskaladUrl: '' as string,
    version: '' as string,
    isDeprecatedVersion: false as boolean,
    usersWithoutPassword: [] as Array<User>,
    usersWithoutRoadBookRole: [] as Array<User>,
    updateUserAccess: false as boolean,
  }),
  getters: {
    hasToDisconnect: (state) => state.user.is_restricted_access ?? false,
  },

  persist: {
    storage: window.sessionStorage,
  },
  actions: {
    setLoggingOut(value: boolean) {
      this.loggingOut = value;
    },
    setVersion(value: string) {
      this.version = value;
    },
    deprecatedVersion(value: boolean) {
      this.isDeprecatedVersion = value;
    },
    setUser(user: User) {
      this.user = user;
      getFaro().api.setUser({
        email: user.login,
        username: `${user.last_name} ${user.first_name}`,
        attributes: {
          companyId: user.company_id?.toString() ?? '-',
        },
      });
      if (this.firstConnexion) {
        this.firstConnexion = false;
        this.initialUser = user;
        if (user.roles) {
          this.licencesAccess = user.roles.includes('roadbook_admin_monthly_licences');
          this.updateUserAccess = user.roles.includes('roadbook_admin_user');
          this.webhooksAccess = user.roles.includes('roadbook_admin_webhooks');
        }
      } else {
        if (user.roles) {
          this.licencesAccess = user.roles.includes('roadbook_admin_monthly_licences');
          this.webhooksAccess = user.roles.includes('roadbook_admin_webhooks');
        }
        this.updateUserAccess = false;
      }
      if (user.company_id) {
        this.entityId = user.company_id;
      }
      this.getStatisticsAvailability();
    },
    setUsers(users: Array<User>) {
      this.users = users;
    },
    setUsersWithoutPassword(usersWithoutPassword: Array<User>) {
      this.usersWithoutPassword = usersWithoutPassword;
    },
    setUsersWithoutRoadBookRole(value: Array<User>) {
      this.usersWithoutRoadBookRole = value;
    },
    setNoSessions(value: boolean) {
      this.noSessions = value;
    },
    setMaxSessions(value: number) {
      this.maxSessions = value;
    },
    setActiveSessions(value: Array<User>) {
      this.activeSessions = value;
    },
    setHasStatisticsAccess(value: boolean) {
      this.hasStatisticsAccess = value;
    },
    setStatisticsEskaladUrl(value: string) {
      this.statisticsEskaladUrl = value;
    },
    async setCurrent(user: User) {
      await apiUserService.connect(user);
    },
    noActiveSessions(body: any) {
      this.setNoSessions(true);
      this.setMaxSessions(body.max_active_sessions);
      this.setActiveSessions(body.online_users);
      if (router.currentRoute.value.name !== 'no_available_sessions') {
        if (this.user.roles && this.user.roles.includes('roadbook_access')) {
          router.push('/no_available_sessions');
        } else {
          router.push('/unauthorized');
        }
      }
    },
    async updateSession() {
      if (!this.noSessions && this.user.roles && this.user.roles.includes('roadbook_access')) {
        const response = await apiUserService.updateSession();
        if (response && response.status && response.status === 412) {
          this.noActiveSessions(response);
        } else if (response) {
          if (this.version.length && parseInt(this.version) !== parseInt(__APP_VERSION__)) {
            this.deprecatedVersion(true);
          } else {
            this.deprecatedVersion(false);
          }
        } else {
          this.setNoSessions(false);
        }
      }
    },
    async logout() {
      if (useRoadbookStore().id !== null && !useRoadbookStore().metadata.is_finished) {
        deleteRoadBook();
      }
      this.setLoggingOut(true);
      this.setVersion('');
      document.cookie = 'SESSIONID=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'SESSIONID=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/v2/erp';
      await apiUserService.logout();
    },
    async getCurrent() {
      const user: User = await apiUserService.getCurrent();
      if (!user.status) {
        this.setUser(user);
      }
      return user;
    },
    async getStatisticsAvailability() {
      try {
        const response = await client().get('stats/dashboard');
        if (response.status === 200) {
          this.setHasStatisticsAccess(true);
          this.setStatisticsEskaladUrl(response.data.url);
        }
      } catch (e) {
        console.warn('No acces to dashboard cause of 403 (dev)');
      }
    },
    async getUsers() {
      const users: Array<User> = await apiUserService.getUsers();
      this.setUsers(users);
    },
    async getUsersWithoutRoadBookRole() {
      const usersWithoutRoadBookRole: Array<User> =
        await apiUserService.getUsersWithoutRoadBookRole();
      this.setUsersWithoutRoadBookRole(usersWithoutRoadBookRole);
    },
    async getUsersWithoutPassword() {
      const usersWithoutPassword: Array<User> = await apiUserService.getUsersWithoutPassword();
      this.setUsersWithoutPassword(usersWithoutPassword);
    },
  },
});
